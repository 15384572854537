
import React from "react";
import "./App.css";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Pairing from "./Pairing/Pairing";

function App() {
  return (
    <div className='h-screen w-screen bg-primary-900'>
      <Router>
        <Routes>
          <Route path="/" element={<Pairing />}></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App;
