import React, { useEffect } from 'react'
import CheckSuccessAnimated from '../components/CheckSuccessAnimated/CheckSuccessAnimated'
import { useMutation, useSubscription } from '@apollo/client'
import { GET_PAIRING_CODE, SUB_TRACKING_PAIRING_SESSION } from './queries'
import { ClientJS } from 'clientjs';
import Spinner from '../components/Spinner';

const clientjs = new ClientJS();

export default function Pairing() {

  const fingerprint = clientjs.getFingerprint();
  const userAgent = clientjs.getUserAgent();
  const mobileDevice = clientjs.isMobile();

  const { data: pairingData } = useSubscription(SUB_TRACKING_PAIRING_SESSION, {
    variables: {
      fingerprint: `${fingerprint}`,
    }
  });
  const [generatePairingCode, {data: pairingCode}] = useMutation(GET_PAIRING_CODE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [loggedIn, setLoggedIn] = React.useState(false)

  const generateCode = async () => {
    await generatePairingCode({
      variables: {
        userAgent,
        fingerprint: `${fingerprint}`,
        mobileDevice
      }
    })
  }

  useEffect(() => {

    generateCode();

    const interval = setInterval(() => {
      generateCode();
    }, 840000);

    return () => clearInterval(interval);

  }, [])

  useEffect(() => {
    if (pairingData?.trackingPairingSession) {
      setLoggedIn(true)

      /** Rediriger la page vers l'url de redirection au bout de 3 secondes */
      setTimeout(() => {
        window.location.href = pairingData.trackingPairingSession.redirUrl
      }, 3000)
    }
  }, [pairingData])

  return (
      <div className='flex flex-col h-full w-full items-center justify-center text-center text-white' >
        <div className='flex flex-col gap-12 items-center'>
          <div>
            <img src="/images/blue-logo-gold.png" className='w-52' alt="Logo entreprise bt-blue"/>
          </div>
          <div>
            <h1 className='text-2xl font-bold'>Connecter un appareil</h1>
          </div>
          <div className='text-xl leading-normal'>
            <p>Vous devez connecter votre appareil à votre Espace Client BLUE.<br/>
            Rendez vous sur : <span className='italic'><a href='https://clients.bt-blue.com' className='font-semibold' target='_blank' rel="noreferrer">clients.bt-blue.com</a>  - profil - appairage</span> et saisissez ce code :</p>
          </div>
          {
            loggedIn && (
              <div className='flex flex-col italic'>
                <CheckSuccessAnimated />
                <div>Vous allez être redirigé...</div>
              </div>
            )
          }
          {
            !loggedIn && (
              <div className='bg-primary-700 w-full p-4 text-4xl font-semibold tracking-widest rounded-md border-4 border-secondary-500'>
                {
                  pairingCode?.generateDevicePairingCode || (
                    <div className='flex flex-col space-y-4 items-center justify-center'>
                      <Spinner size='w-8 h-8' color='white'/>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
  )
}