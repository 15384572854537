import { gql } from "@apollo/client";

export const GET_PAIRING_CODE = gql`
  mutation GetPairingCode($userAgent: String, $fingerprint: String, $mobileDevice: Boolean) {
    generateDevicePairingCode(userAgent: $userAgent, fingerprint: $fingerprint, mobileDevice: $mobileDevice)
  }
`

export const SUB_TRACKING_PAIRING_SESSION = gql`
  subscription TrackingPairingSession($fingerprint: String) {
    trackingPairingSession(fingerprint: $fingerprint) {
      deviceSessionViewCode
      redirUrl
    }
  }
`;